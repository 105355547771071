'use client';

import { Flex, Skeleton, Box, Text, Button } from '@chakra-ui/react';
import { FC, useEffect, useMemo, useRef } from 'react';
import { PATH } from '@/constants/paths';
import { useGetInStockProductIdsQuery } from '@/store/apis/app.products';
import { WhereBuilder } from '@loopback/filter';
import { useGetProductVariantsQuery } from '@/store/apis/app.product-variants';
import { PRODUCT_VARIANT_REFRESH_INTERVAL } from '@/constants/product';
import { mapAppProductVariantToProductItem } from '@/utils/product';
import SimpleProductItem from '@/components/Product/ProductItem/SimpleProductItem';
import SectionContainer from './SectionContainer';
import ReduxProviders from '@/providers/ReduxProvider';
import Title from '@/components/UI/Title';
import Link from 'next/link';
import colors from '@/constants/theme/colors';
import { ArrowLeftLineIcon, ArrowRightLineIcon } from '@/components/Icons';
import DragToScrollContainer from '@/components/UI/DragToScrollContainer';
import { StockAvailability } from '@/types/api/app/product';
import { useGetTagListQuery } from '@/store/apis/app.tags';
import { flatten, intersection, map } from 'lodash';
import { getIdsFromTags } from '@/utils/tag';

interface NewArrivalsSectionProps {
  newArrivalsSection?: {
    title?: string;
    subTitle?: string;
    link?: string;
  };
}

const NewArrivalsSectionBase: FC<NewArrivalsSectionProps> = ({
  newArrivalsSection = {
    title: 'Jewelry as an Investment',
    subTitle:
      'Beautiful, valuable, and transparent. Our gold jewelry is a smart investment for lasting beauty and financial security.',
    link: '/blogs',
  },
}) => {
  const {
    title = 'Jewelry as an Investment',
    subTitle = 'Beautiful, valuable, and transparent. Our gold jewelry is a smart investment for lasting beauty and financial security.',
    link = '/blogs',
  } = newArrivalsSection;

  const { data: tags = [], isFetching: isFetchingTags } = useGetTagListQuery({
    name: '%New Arrivals',
  });

  const { tagProductIds, tagVariantIds } = useMemo(() => {
    return getIdsFromTags(tags);
  }, [tags]);

  const {
    data: inStockProductsIds = [],
    isLoading: isGetInStockProductIdsLoading,
  } = useGetInStockProductIdsQuery();

  const productVariantsWhere = useMemo(() => {
    const productVariantsWhereBuilder = new WhereBuilder();
    productVariantsWhereBuilder.and([
      {
        stockAvailability: { neq: StockAvailability.Deactivated },
      },
    ]);
    productVariantsWhereBuilder.or([
      { id: { inq: tagVariantIds } },
      { productId: { inq: intersection(tagProductIds, inStockProductsIds) } },
    ]);

    return productVariantsWhereBuilder.build();
  }, [inStockProductsIds, tagProductIds]);

  const inStockProductIdsCount = inStockProductsIds.length;

  const {
    data: productVariants = [],
    isLoading: isGetProductVariantsLoading,
    refetch: refetchVariants,
  } = useGetProductVariantsQuery(
    {
      page: 1,
      pageSize: 12,
      order: ['createdAt DESC'],
      where: productVariantsWhere,
    },
    {
      pollingInterval: PRODUCT_VARIANT_REFRESH_INTERVAL,
    }
  );

  const scrollingContainerRef = useRef<HTMLDivElement>(null);

  const showLoading =
    isGetInStockProductIdsLoading || isGetProductVariantsLoading;

  const productItems = useMemo(() => {
    const variants = productVariants ?? [];
    return variants.map(mapAppProductVariantToProductItem);
  }, [productVariants]);

  const _mobileTitle = (
    <Flex
      display={{ base: 'flex', md: 'none' }}
      flexDir="column"
      alignItems="flex-start"
      gap="1rem"
      justifyContent="center"
    >
      <Title textAlign="left" fontSize="2.25rem" lineHeight="2.75rem">
        {title}
      </Title>
      <Text
        textAlign="left"
        color={colors.primary[300]}
        fontWeight="300"
        fontSize="1rem"
        lineHeight="1.5rem"
        whiteSpace="pre-wrap"
      >
        {subTitle}
      </Text>
      <Link href={PATH.shopNewArrivals}>
        <Button
          variant="tertiarySm"
          color={colors.primary[300]}
          fontWeight="600"
          p="0"
          minH="none"
        >
          Shop New Arrivals
        </Button>
      </Link>
    </Flex>
  );

  const _desktopTitle = (
    <Flex
      flexDir="column"
      alignItems="flex-end"
      gap="1rem"
      minW={{ base: 'none', '2xl': '34.75rem' }}
      justifyContent="center"
      h={{ base: '24rem', xl: '28.375rem' }}
      display={{ base: 'none', md: 'flex' }}
      scrollSnapAlign="end"
    >
      <Title
        textAlign="right"
        w={{ base: '16rem', xl: '24rem' }}
        fontSize="2.25rem"
        lineHeight="2.75rem"
      >
        {title}
      </Title>
      <Text
        w="16rem"
        textAlign="right"
        color={colors.primary[300]}
        fontWeight="300"
        whiteSpace="pre-wrap"
      >
        {subTitle}
      </Text>
      <Link href={PATH.shopNewArrivals}>
        <Button
          variant="tertiarySm"
          color={colors.primary[300]}
          fontWeight="600"
          p="0"
          minH="none"
        >
          Shop New Arrivals
        </Button>
      </Link>
    </Flex>
  );

  const handleScrollLeft = () => {
    scrollingContainerRef.current?.scrollTo({
      top: 0,
      left:
        scrollingContainerRef.current.scrollLeft -
        scrollingContainerRef.current?.clientWidth / 2,
      behavior: 'smooth',
    });
  };

  const handleScrollRight = () => {
    scrollingContainerRef.current?.scrollTo({
      top: 0,
      left:
        scrollingContainerRef.current.scrollLeft +
        scrollingContainerRef.current?.clientWidth / 2,
      behavior: 'smooth',
    });
  };

  const _scrollController = (
    <Flex alignItems="center" gap="0.5rem">
      <ArrowLeftLineIcon
        size="1.25rem"
        cursor="pointer"
        onClick={handleScrollLeft}
      />
      <ArrowRightLineIcon
        size="1.25rem"
        cursor="pointer"
        onClick={handleScrollRight}
      />
    </Flex>
  );

  const _listContent = (
    <>
      {showLoading ? (
        <>
          {Array.apply(null, Array(5)).map((_, idx) => (
            <Box
              key={idx}
              w={{ base: '15rem', xl: '21.25rem' }}
              h={{ base: '24rem', xl: '28.375rem' }}
            >
              <Skeleton
                width={{ base: '15rem', xl: '21.25rem' }}
                height={{ base: '24rem', xl: '28.375rem' }}
              />
            </Box>
          ))}
        </>
      ) : (
        productItems.map((item, idx) => (
          <SimpleProductItem
            containerProps={{
              scrollSnapAlign: 'start',
            }}
            productItem={item}
            key={`${item.productVariantId}_${idx}`}
            imageProps={{
              loading: 'eager',
            }}
          />
        ))
      )}
    </>
  );

  useEffect(() => {
    refetchVariants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inStockProductIdsCount]);

  return (
    <SectionContainer
      bgColor="white"
      p="1rem"
      gap={{ base: '2rem', xl: '1rem' }}
    >
      {_mobileTitle}
      <Flex flexDir="column" gap="1rem">
        {/* <Flex gap="3rem">
          <Box
            w={{ base: '16rem', xl: '24rem', '2xl': '34.75rem' }}
            display={{ base: 'none', md: 'block' }}
          />
          <Flex alignItems="center" justifyContent="space-between" flex="1">
            <Box />
            {_scrollController}
          </Flex>
        </Flex> */}
        <DragToScrollContainer
          containerProps={{
            gap: '3rem',
            h: { base: '25rem', xl: '29.375rem' },
          }}
          ref={scrollingContainerRef}
        >
          {_desktopTitle}
          <Flex gap="1rem">{_listContent}</Flex>
        </DragToScrollContainer>
      </Flex>
    </SectionContainer>
  );
};

interface NewArrivalsSectionSkeletonProps extends NewArrivalsSectionProps {}
export const NewArrivalsSectionSkeleton: FC<NewArrivalsSectionSkeletonProps> = (
  props
) => {
  const {
    newArrivalsSection = {
      title: 'Jewelry as an Investment',
      subTitle:
        'Beautiful, valuable, and transparent. Our gold jewelry is a smart investment for lasting beauty and financial security.',
      link: '/blogs',
    },
  } = props;

  const { title, subTitle, link } = newArrivalsSection;

  return (
    <SectionContainer bgColor="white" p="1rem" gap="1rem">
      {/* <Flex gap="3rem">
        <Box w="34.75rem" display={{ base: 'none', xl: 'block' }} />
        <Link href={PATH.shopNewArrivals}>
          <Flex alignItems="center" gap="0.25rem">
            <Text
              fontSize={{ base: '1rem', xl: '1.125rem' }}
              fontWeight="600"
              lineHeight="1.5rem"
            >
              Shop New Arrivals
            </Text>
            <ArrowRightLineIcon size="1.25rem" />
          </Flex>
        </Link>
      </Flex> */}
      <Flex
        gap="3rem"
        h={{ base: '21rem', xl: '29.375rem' }}
        overflow={{ base: 'auto', xl: 'hidden' }}
        _hover={{ overflow: 'auto' }}
        userSelect="none"
      >
        <Flex
          minW={{ base: '10rem', xl: '34.75rem' }}
          justifyContent="flex-end"
          h={{ base: '24rem', xl: '28.375rem' }}
        >
          <Flex
            flexDir="column"
            alignItems="flex-end"
            gap="1rem"
            justifyContent="center"
          >
            <Title
              textAlign="right"
              w="24rem"
              fontSize="2.25rem"
              lineHeight="2.75rem"
            >
              {title}
            </Title>
            <Text
              w="16rem"
              textAlign="right"
              color={colors.primary[300]}
              fontWeight="300"
              whiteSpace="pre-wrap"
            >
              {subTitle}
            </Text>
            {!!link && (
              <Link href={link}>
                <Button
                  variant="tertiarySm"
                  color={colors.primary[300]}
                  fontWeight="600"
                >
                  Learn more
                </Button>
              </Link>
            )}
          </Flex>
        </Flex>
        <Flex gap={{ base: '1rem', xl: '1.5rem' }}>
          {Array.apply(null, Array(5)).map((_, idx) => (
            <Box
              key={idx}
              w={{ base: '15rem', xl: '21.25rem' }}
              h={{ base: '24rem', xl: '28.375rem' }}
            >
              <Skeleton
                width={{ base: '15rem', xl: '21.25rem' }}
                height={{ base: '24rem', xl: '28.375rem' }}
              />
            </Box>
          ))}
        </Flex>
      </Flex>
    </SectionContainer>
  );
};

export default function NewArrivalsSection(props: NewArrivalsSectionProps) {
  return (
    <ReduxProviders
      persistGateProps={{
        loading: <NewArrivalsSectionSkeleton />,
      }}
    >
      <NewArrivalsSectionBase {...props} />
    </ReduxProviders>
  );
}
