'use client';

import { FC } from 'react';
import SectionContainer from './SectionContainer';
import SectionTitle from './SectionTitle';
import { Flex, Skeleton, Box } from '@chakra-ui/react';
import { PATH } from '@/constants/paths';
import Image from 'next/image';
import InfoTag from '@/components/InfoTag';
import { useGetCollectionsQuery } from '@/store/apis/app.collections';
import ReduxProviders from '@/providers/ReduxProvider';

interface CollectionsSectionProps {}
const CollectionsSectionBase: FC<CollectionsSectionProps> = ({}) => {
  const { data: collections = [], isLoading } = useGetCollectionsQuery();

  if (!collections) return null;

  return (
    <SectionContainer>
      <Flex
        h={{ base: 'auto', md: '30rem', xl: '58.5rem' }}
        w="100%"
        flexDir={{ base: 'column', md: 'row' }}
      >
        {isLoading ? (
          <>
            {Array.apply(null, Array(2)).map((_, idx) => (
              <Box key={idx} w="100%" aspectRatio="1/1">
                <Skeleton w="100%" h="100%" />
              </Box>
            ))}
          </>
        ) : (
          collections.slice(0, 2).map((collection) => {
            const { title, coverPhotoUrls, id, blurDataUrl, slug } = collection;
            const collectionDetailsUrl = `${PATH.collections}/${slug}`;

            return (
              <Flex
                flexDir="column"
                position="relative"
                w="100%"
                aspectRatio={{ base: '1/1.25', md: '1/1' }}
                justifyContent="flex-end"
                p="1rem"
                overflow="hidden"
                key={id}
              >
                <Image
                  alt={title}
                  src={coverPhotoUrls[0]}
                  fill
                  sizes="(max-width: 992px) 359px, 676px"
                  quality={100}
                  style={{ objectFit: 'cover' }}
                  placeholder={!!blurDataUrl ? 'blur' : 'empty'}
                  loading="lazy"
                  blurDataURL={blurDataUrl}
                  unoptimized
                />
                <Flex flexDir="column" zIndex="10" gap="0.5rem">
                  <InfoTag
                    info={title}
                    textProps={{
                      fontSize: {
                        base: '1.125rem',
                        '2xl': '1.5rem',
                      },
                      lineHeight: {
                        base: '1.5rem',
                        '2xl': '2rem',
                      },
                    }}
                  />
                  <SectionTitle
                    title="Browse Collection"
                    link={collectionDetailsUrl}
                  />
                </Flex>
              </Flex>
            );
          })
        )}
      </Flex>
    </SectionContainer>
  );
};

const CollectionsSection: FC<CollectionsSectionProps> = ({ ...props }) => {
  return (
    <ReduxProviders
      persistGateProps={{
        loading: (
          <SectionContainer>
            <SectionTitle title="Shop Collection" />
            <Flex
              h={{ base: 'auto', '2xl': '42.25rem' }}
              w="100%"
              gap="1.5rem"
              flexDir={{ base: 'column', '2xl': 'row' }}
            >
              {Array.apply(null, Array(2)).map((_, idx) => (
                <Box key={idx} w="100%" aspectRatio="1/1">
                  <Skeleton w="100%" h="100%" />
                </Box>
              ))}
            </Flex>
          </SectionContainer>
        ),
      }}
    >
      <CollectionsSectionBase {...props} />
    </ReduxProviders>
  );
};

export default CollectionsSection;
