import appApis, { APP_QUERY_TAG } from '@/store/apis/app';
import { FilterBuilder, WhereBuilder } from '@loopback/filter';
import { ITag } from '@/types/api/app/tag';

const appTagsApis = appApis.injectEndpoints({
  endpoints: (build) => ({
    getTagList: build.query<ITag[], { name?: string }>({
      query: ({ name }) => {
        const filterBuilder = new FilterBuilder();
        const whereBuilder = new WhereBuilder();

        if (name) {
          whereBuilder.ilike('name', name);
          const where = whereBuilder.build();
          filterBuilder.where(where);
        }

        filterBuilder.include({ relation: 'taggings' });

        const filter = filterBuilder.build();

        return {
          url: `/tags`,
          params: {
            filter: JSON.stringify(filter),
          },
        };
      },
      providesTags: (result = []) => {
        if (!result) {
          return [{ type: APP_QUERY_TAG.tag, id: '*' }];
        }
        return [
          { type: APP_QUERY_TAG.tag, id: '*' },
          ...result.map(({ id }) => ({ type: APP_QUERY_TAG.tag, id } as const)),
        ];
      },
    }),
    getTagDetails: build.query<ITag, { tagSlug?: string }>({
      query: ({ tagSlug }) => {
        const filterBuilder = new FilterBuilder();
        filterBuilder.include({ relation: 'taggings' });
        const filter = filterBuilder.build();

        return {
          url: `/tags/slugs/${tagSlug}`,
          params: {
            filter: JSON.stringify(filter),
          },
        };
      },
      providesTags: (result, error, arg) =>
        result
          ? [{ type: APP_QUERY_TAG.tag, id: result.id }]
          : [APP_QUERY_TAG.tag],
    }),
  }),
});

export const { useGetTagListQuery, useGetTagDetailsQuery } = appTagsApis;

export default appTagsApis;
