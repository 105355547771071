'use client';

import dynamic from 'next/dynamic';
import { FC } from 'react';
import { IArticle } from '@/types/article';
import PageContainer from '@/components/UI/Page/PageContainer';

import { ISanityHomePage } from '@/types/sanity/home-page';
import { urlFor } from '@/utils/sanity/sanity';

import { Skeleton } from '@chakra-ui/react';

import HeroSection from './HeroSection';
import NewArrivalsSection from './NewArrivalsSection';
import CollectionsSection from './CollectionsSection';

const BestSellingSection = dynamic(() => import('./BestSellingSection'), {
  ssr: false,
});
const BlogPostsSection = dynamic(() => import('./BlogPostsSection'), {
  ssr: false,
});
const AurevDifferenceSection = dynamic(
  () => import('./AurevDifferenceSection'),
  {
    ssr: false,
  }
);

const CategoriesSection = dynamic(() => import('./CategoriesSection'), {
  ssr: false,
  loading: () => <Skeleton h={{ base: 'auto', xl: '36.5rem' }} w="100%" />,
});

export interface HomeProps {
  articles: IArticle[];
  homePage: ISanityHomePage;
  isDeviceMobile?: boolean;
}
const Home: FC<HomeProps> = ({ isDeviceMobile, articles = [], homePage }) => {
  const {
    aurevDifferenceSection,
    heroSection,
    newArrivalsSection,
    bestSellingSection,
    categorySection,
  } = homePage;

  const heroImage1Url = urlFor(heroSection.hero1.image)
    .ignoreImageParams()
    .url()
    .toString();

  const heroImage2Url = urlFor(heroSection.hero2.image)
    .ignoreImageParams()
    .url()
    .toString();

  const heroImageMobileUrl = urlFor(heroSection.heroMobile.image)
    .ignoreImageParams()
    .url()
    .toString();

  return (
    <PageContainer
      maxW={{ base: '100vw', '2xl': '100%' }}
      pt="0.5rem !important"
    >
      <HeroSection
        heroSection={{
          hero1: {
            ...heroSection.hero1,
            imageUrl: heroImage1Url,
            imageBlurDataUrl: heroSection.hero1.image.lqip,
          },
          hero2: {
            ...heroSection.hero2,
            imageUrl: heroImage2Url,
            imageBlurDataUrl: heroSection.hero2.image.lqip,
          },
          heroMobile: {
            ...heroSection.heroMobile,
            imageUrl: heroImageMobileUrl,
            imageBlurDataUrl: heroSection.heroMobile.image.lqip,
          },
        }}
        isDeviceMobile={isDeviceMobile}
      />
      <NewArrivalsSection newArrivalsSection={newArrivalsSection} />
      <CollectionsSection />
      <CategoriesSection categorySection={categorySection} />
      <AurevDifferenceSection aurevDifferenceSection={aurevDifferenceSection} />
      <BestSellingSection bestSellingSection={bestSellingSection} />
      <BlogPostsSection articles={articles} />
    </PageContainer>
  );
};

export default Home;
