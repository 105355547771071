import appApis from '@/store/apis/app';
import { APP_QUERY_TAG } from '@/store/apis/app';
import { IAppCollection } from '@/types/api/app/collection';

const appCollectionsApis = appApis.injectEndpoints({
  endpoints: (build) => ({
    // products
    getCollections: build.query<IAppCollection[], void>({
      query: () => ({
        url: '/collections',
        method: 'GET',
      }),
      providesTags: [APP_QUERY_TAG.collections],
    }),
    getCollectionsCount: build.query<number, void>({
      query: () => ({
        url: '/collections/count',
        method: 'GET',
      }),
      transformResponse: (response: { count: number }) => {
        if (!response) return 0;
        const { count } = response;
        return count;
      },
      providesTags: [APP_QUERY_TAG.collectionsCount],
    }),
    getCollectionDetails: build.query<
      IAppCollection,
      { collectionSlug: string }
    >({
      query: ({ collectionSlug }) => ({
        url: `/collections/slugs/${collectionSlug}`,
        method: 'GET',
      }),
      providesTags: [APP_QUERY_TAG.collectionDetails],
    }),
  }),
});

export const {
  useGetCollectionsQuery,
  useGetCollectionsCountQuery,
  useGetCollectionDetailsQuery,
} = appCollectionsApis;

export default appCollectionsApis;
