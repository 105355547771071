'use client';

import { Flex, FlexProps } from '@chakra-ui/react';
import { FC } from 'react';

interface SectionContainerProps extends FlexProps {}
const SectionContainer: FC<SectionContainerProps> = ({
  children,
  ...flexProps
}) => {
  return (
    <Flex
      flexDir="column"
      gap={{ base: '1rem', '2xl': '1.5rem' }}
      w="100%"
      {...flexProps}
    >
      {children}
    </Flex>
  );
};

export default SectionContainer;
