'use client';

import { FC } from 'react';
import { Flex, Box, Text, Button } from '@chakra-ui/react';
import Image from 'next/image';
import { sanityLoader } from '@/utils/sanity/sanity';
import Link from 'next/link';
import colors from '@/constants/theme/colors';

export type HeroSectionProps = {
  heroSection?: {
    hero1: {
      imageUrl: string;
      imageBlurDataUrl?: string;
      title: string;
      subTitle: string;
      action: string;
      actionLink: string;
    };
    hero2: {
      imageUrl: string;
      imageBlurDataUrl?: string;
      title: string;
      subTitle: string;
      action: string;
      actionLink: string;
    };
    heroMobile: {
      imageUrl: string;
      imageBlurDataUrl?: string;
      title: string;
      subTitle: string;
      action: string;
      actionLink: string;
    };
  };
  isDeviceMobile?: boolean;
};

const HeroSection: FC<HeroSectionProps> = ({
  isDeviceMobile,
  heroSection = {
    hero1: {
      imageUrl: '',
      imageBlurDataUrl: '',
      title: '',
      subTitle: '',
      action: '',
      actionLink: '',
    },
    hero2: {
      imageUrl: '',
      imageBlurDataUrl: '',
      title: '',
      subTitle: '',
      action: '',
      actionLink: '',
    },
    heroMobile: {
      imageUrl: '',
      imageBlurDataUrl: '',
      title: '',
      subTitle: '',
      action: '',
      actionLink: '',
    },
  },
}) => {
  const { hero1, hero2, heroMobile } = heroSection;

  return (
    <Flex
      w="100%"
      minH={{ base: 'calc(100dvh - 5.5rem)', lg: 'calc(100vh - 7.5rem)' }}
      position="relative"
      flexDir={{ base: 'column', lg: 'row' }}
      bgColor={colors.primary[25]}
    >
      {/* mobile */}
      <Box
        h="calc(100dvh - 5.5rem)"
        w="100%"
        position="relative"
        display={{ base: 'block', lg: 'none' }}
      >
        <Image
          alt={'hero-mobile'}
          src={heroMobile.imageUrl}
          blurDataURL={heroMobile.imageBlurDataUrl}
          placeholder={!!heroMobile.imageBlurDataUrl ? 'blur' : 'empty'}
          fill
          priority
          style={{ objectFit: 'cover' }}
          quality={100}
          unoptimized
        />
        <HeroContent
          title={heroMobile.title}
          subTitle={heroMobile.subTitle}
          action={heroMobile.action}
          actionLink={heroMobile.actionLink}
        />
      </Box>
      {/* desktop */}
      <>
        <Box
          h={{ base: 'calc(100vh - 5.5rem)', lg: 'calc(100vh - 7.5rem)' }}
          w="100%"
          position="relative"
          display={{ base: 'none', lg: 'block' }}
        >
          <Image
            alt={'hero-1'}
            src={hero1.imageUrl}
            blurDataURL={hero1.imageBlurDataUrl}
            placeholder={!!hero1.imageBlurDataUrl ? 'blur' : 'empty'}
            fill
            priority
            style={{ objectFit: 'cover' }}
            quality={100}
            unoptimized
          />
          <HeroContent
            title={hero1.title}
            subTitle={hero1.subTitle}
            action={hero1.action}
            actionLink={hero1.actionLink}
          />
        </Box>
        <Box
          h={{ base: 'calc(100vh - 5.5rem)', lg: 'calc(100vh - 7.5rem)' }}
          w="100%"
          position="relative"
          display={{ base: 'none', lg: 'block' }}
        >
          <Image
            alt={'hero-2'}
            src={hero2.imageUrl}
            blurDataURL={hero2.imageBlurDataUrl}
            placeholder={!!hero2.imageBlurDataUrl ? 'blur' : 'empty'}
            fill
            loader={sanityLoader}
            style={{ objectFit: 'cover' }}
            quality={100}
            priority
            unoptimized
          />
          <HeroContent
            title={hero2.title}
            subTitle={hero2.subTitle}
            action={hero2.action}
            actionLink={hero2.actionLink}
          />
        </Box>
      </>
    </Flex>
  );
};

interface HeroContentProps {
  title: string;
  subTitle: string;
  actionLink: string;
  action: string;
}
const HeroContent: FC<HeroContentProps> = ({
  title,
  subTitle,
  actionLink,
  action,
}) => {
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      gap="1.25rem"
      position="absolute"
      zIndex="10"
      left="0"
      right="0"
      top="0"
      bottom="3rem"
      marginX="auto"
      marginTop="auto"
      marginBottom="unset"
      h="fit-content"
      px="2rem"
    >
      <Flex
        w="100%"
        flexDir="column"
        gap={{ base: '0.5rem', lg: '0.75rem' }}
        alignItems="center"
        maxW={{ base: '20rem', lg: 'none' }}
        position="relative"
      >
        <Text
          as="h1"
          textAlign="center"
          fontSize={{ base: '1.5rem', lg: '1.75rem', '2xl': '2rem' }}
          lineHeight={{ base: '1.5rem', lg: '1.75rem', '2xl': '2rem' }}
          fontWeight={{ base: '500', lg: '400' }}
          color={colors.white}
          textShadow="0px 0px 16px rgba(0, 0, 0, 0.50)"
          letterSpacing={{ base: '-0.03rem', lg: '-0.06rem' }}
        >
          {title}
        </Text>
        <Text
          textAlign="center"
          fontSize="0.9375rem"
          lineHeight="0.9375rem"
          w={{ base: '80vw', lg: '40vw', '2xl': '40rem' }}
          fontWeight="500"
          color={colors.white}
          whiteSpace="pre-wrap"
          textShadow="0px 0px 16px rgba(0, 0, 0, 0.50)"
        >
          {subTitle}
        </Text>
      </Flex>
      {!!actionLink && (
        <Link href={actionLink}>
          <Button
            variant="tertiarySm"
            fontSize="1.125rem"
            lineHeight="1.5rem"
            color={colors.white}
            bgColor="rgba(0, 0, 0, 0.20)"
            border={`1px solid ${colors.white}`}
            minW="4.25rem"
            minH="2.5rem"
            py="0.625rem"
            px="1.5rem"
          >
            {action}
          </Button>
        </Link>
      )}
    </Flex>
  );
};

export default HeroSection;
