import { ITag } from '@/types/api/app/tag';
import { filter, flatten, map } from 'lodash';

export const getIdsFromTags = (tags: ITag[]) => {
  const taggings = flatten(map(tags, ({ taggings }) => taggings ?? []));
  const tagProductIds =
    map(
      filter(taggings, (t) => t.taggableType === 'product'),
      ({ taggableId }) => taggableId
    ) ?? [];
  const tagVariantIds =
    map(
      filter(taggings, (t) => t.taggableType === 'product-variant'),
      ({ taggableId }) => taggableId
    ) ?? [];

  return { tagProductIds, tagVariantIds };
};
